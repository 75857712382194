.App {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo {
	width: 10rem;
}

.flyout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size: 6rem;
}

.home-feature-box span {
	display: block;
	color: #111;
	font-weight: bold;
	margin-top: 1.5rem;
}

.example-components-list li>a {
	color: #495057;
}

.example-components-list li:last-child>a {
	border-bottom: 0;
}

.example-components-list li>a .fa {
	color: rgba(0, 0, 0, .35);
	float: right;
}

/* div[class^="col"],
div[class*=" col"] {
	border: 1px solid black;
} */